export const combo = {
  setDinners: [
    {
      name: "Dinner for One",
      number: "D1",
      price: 19.99,
      combo: true,
      items: [
        "Spring Roll (1)",
        "Ginger Fried Beef",
        "Stir Fried Vegetables",
        "Chicken Fried Rice",
      ],
    },
    {
      name: "Dinner for Two",
      number: "D2",
      price: 41.99,
      combo: true,
      items: [
        "Spring Roll (2)",
        "Sweet & Sour Pork",
        "Stir Fried Vegetables",
        "Ginger Fried Beef",
        "Chicken Fried Rice",
      ],
    },
    {
      name: "Dinner for Four",
      number: "D4",
      price: 85.99,
      combo: true,
      soup: true,
      items: [
        "Spring Roll (4)",
        "Wonton Soup OR Hot & Sour Soup",
        "Sweet & Sour Pork",
        "Ginger Fried Beef",
        "Chicken with Mixed Vegetables",
        "Chicken Fried Rice",
      ],
    },
    {
      name: "Dinner for Six",
      number: "D6",
      price: 119.99,
      combo: true,
      soup: true,

      items: [
        "Spring Roll (6)",
        "Wonton Soup OR Hot & Sour Soup",
        "Sweet & Sour Pork",
        "Ginger Fried Beef",
        "Chicken with Mixed Vegetables",
        "Beef with Broccoli",
        "Shanghai Noodles with Shredded Pork",
        "Chicken Fried Rice",
      ],
    },
    {
      name: "Dinner for Eight",
      number: "D8",
      price: 157.99,
      combo: true,
      soup: true,

      items: [
        "Spring Roll (8)",
        "Wonton Soup OR Hot & Sour Soup (2)",
        "Chicken Fried Rice (2)",
        "Ginger Fried Beef",
        "Sweet & Sour Pork",
        "Salt & Pepper Squid",
        "Chicken with Mixed Vegetables",
        "Shanghai Noodles with Shredded Pork",
        "Beef with Broccoli",
      ],
    },
  ],
};
