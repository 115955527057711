import {
  Flex,
  Text,
  SimpleGrid,
  UnorderedList,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";

import { offline } from "../../data/offline";
import ClosePopup from "./ClosePopup";
import Popup from "./Popup";
import moment from "moment";
moment().format();

function ComboButton({ item }) {
  const {
    isOpen: isItemOpen,
    onOpen: onItemOpen,
    onClose: onItemClose,
  } = useDisclosure();
  const {
    isOpen: isClosedOpen,
    onOpen: onClosedOpen,
    onClose: onClosedClose,
  } = useDisclosure();

  return (
    <>
      <Flex
        border="1px"
        borderColor="#c4c4c4"
        fontFamily="body"
        fontWeight={700}
        fontSize="14px"
        justify="center"
        onClick={() => {
          let timeFlag = offline();
          if (timeFlag) {
            onItemOpen();
          } else {
            onClosedOpen();
          }
        }}
        _hover={{
          boxShadow: "base",
          bg: "white",
          cursor: "pointer",
        }}
      >
        <Flex justifyContent="space-between" width="93%" mt="1rem" mb="1rem">
          <Flex align="flex-start" direction="column">
            <Text>{item.name}</Text>

            <SimpleGrid columns={1} spacing={1} mt="1rem" fontFamily="body">
              {item.items.map((item, i) => {
                return (
                  <UnorderedList
                    key={"dinner-set-item: " + i}
                    fontWeight={400}
                    fontSize="14px"
                  >
                    <ListItem>{item}</ListItem>
                  </UnorderedList>
                );
              })}
            </SimpleGrid>
          </Flex>
          <Text>{"$" + item.price.toFixed(2)}</Text>
        </Flex>
      </Flex>
      <Popup
        item={item}
        isOpen={isItemOpen}
        onOpen={onItemOpen}
        onClose={onItemClose}
      ></Popup>
      <ClosePopup
        isOpen={isClosedOpen}
        onOpen={onClosedOpen}
        onClose={onClosedClose}
      ></ClosePopup>
    </>
  );
}

export default ComboButton;
