import React from "react";
import { Flex, Text, VStack, UnorderedList, ListItem } from "@chakra-ui/react";
function Offers(props) {
  return (
    <Flex direction="column" fontFamily="innerbody" align="flex-start">
      <VStack mt="3rem">
        <Text alignSelf="flex-start" fontSize="32px" fontWeight={700}>
          OFFERS
        </Text>
        <Text fontSize={{ base: "18px", md: "24px" }}>
          One of the two options may be chosen at checkout if subtotal exceeds
          required amount:
        </Text>
      </VStack>

      <Flex mt="1rem" direction={{ base: "column", md: "row" }}>
        <Flex
          border="1px"
          borderColor="#C4C4C4"
          p={5}
          direction="column"
          width="100%"
          mr={{ base: "0rem", md: "1rem" }}
          mb={{ base: "1rem", md: "0rem" }}
        >
          <Text fontWeight={700}>
            Discount on pick-up orders over $35 (before GST)
          </Text>
          <UnorderedList>
            <ListItem>
              10% off <i>cash</i> payment
            </ListItem>
            <ListItem>
              5% off <i>credit/debit</i> payment
            </ListItem>
          </UnorderedList>
        </Flex>

        <Flex
          border="1px"
          borderColor="#C4C4C4"
          p={5}
          direction="column"
          width="100%"
        >
          <Text fontWeight={700}>Free dishes on orders over $40**</Text>
          <UnorderedList>
            <ListItem>Spring Rolls over $40</ListItem>
            <ListItem>Chicken Fried Rice over $60</ListItem>
            <ListItem>Ginger Beef over $80</ListItem>
            <ListItem>Salt & Pepper Squid over $90</ListItem>
          </UnorderedList>

          <Flex fontSize="14px" mt="1rem">
            <Text>**</Text>
            <Text>Not Valid on any Set Dinner Combos</Text>
          </Flex>
          <Flex fontSize="14px">
            <Text>**</Text>
            <Text>
              Not valid on Mother’s Day, Father’s day, Christmas Eve, New Year’s
              Eve & New Year’s Day
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Offers;
