import React from "react";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Link,
  useDisclosure,
  Flex,
  Text,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { ChevronRightIcon } from "@chakra-ui/icons";

function HoverMenu(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <Popover
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      onFocus={onOpen}
      onBlur={onClose}
      trigger="hover"
      closeDelay={0}
      openDelay={0}
      offset={[-115, 15]}
      gutter={50}
    >
      <PopoverTrigger
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onFocus={onOpen}
        onBlur={onClose}
      >
        <Flex
          ml="1rem"
          mr="1rem"
          display={["none", "none", "flex", "flex"]}
          borderBottom="1px"
          borderColor="black"
          _hover={{ borderBottom: "1px", borderColor: "white" }}
          ref={btnRef}
          onClick={onOpen}
          fontFamily="body"
        >
          DELIVERY
        </Flex>
      </PopoverTrigger>

      {/*https://github.com/chakra-ui/chakra-ui/issues/4665*/}
      <PopoverContent
        borderColor="black"
        borderWidth="1px"
        borderRadius={0}
        _after={{
          content: "''",
          position: "absolute",
          w: "100%",
          h: `${16}px`,
          top: `-${16}px`,
          left: 0,
        }}
      >
        <PopoverBody>
          <Flex direction="column" mr="0.5rem" ml="0.5rem">
            <Flex
              href="https://www.ubereats.com/ca/store/gold-bistro-chinese-restaurant/zTC34axQRIenOMTM6acV8w"
              as={ChakraLink}
              isExternal
              direction="row"
              justify="space-between"
              mt="0.5rem"
              mb="1rem"
              _hover={{ color: "black" }}
            >
              <Flex align="center">
                <Image
                  src="/ubereats.png"
                  alt="UberEats"
                  width={30}
                  height={30}
                />
                <Text color="primary" ml="9px">
                  UberEats
                </Text>
              </Flex>
              <ChevronRightIcon
                color="primary"
                alignSelf="center"
                h={5}
                w={5}
              />
            </Flex>

            <Flex
              href="https://www.skipthedishes.com/gold-bistro-chinese-restaurant"
              as={ChakraLink}
              isExternal
              direction="row"
              justify="space-between"
              mb="0.5rem"
              _hover={{ color: "black" }}
            >
              <Flex align="center">
                <Image
                  src="/skipthedish.png"
                  alt="SkipTheDishes"
                  width={30}
                  height={30}
                />
                <Text color="primary" ml="9px">
                  SkipTheDishes
                </Text>
              </Flex>
              <ChevronRightIcon
                color="primary"
                alignSelf="center"
                h={5}
                w={5}
              />
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default HoverMenu;
