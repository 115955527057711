import moment from "moment";
moment().format();

export const offline = () => {
  const currentTime = moment().add(0, "minutes");

  //online orders from 11:00AM - 1:45PM
  const startLunchTime = moment("11:00am", "hh:mm A");
  const endLunchTime = moment("1:45pm", "hh:mm A");

  //online orders from 3:00PM - 9:15PM
  const startDinnerTime = moment("3:00pm", "hh:mm A");
  const endDinnerTime = moment("9:15pm", "hh:mm A");

  //current day
  const day = moment().format("dddd");
  let lunchTimeFlag = currentTime.isBetween(startLunchTime, endLunchTime);
  const dinnerTimeFlag = currentTime.isBetween(startDinnerTime, endDinnerTime);
  let timeFlag;

  if (
    (day === "Saturday" && lunchTimeFlag) ||
    (day === "Sunday" && lunchTimeFlag)
  ) {
    timeFlag = false;
  } else if (lunchTimeFlag || dinnerTimeFlag) {
    timeFlag = true;
  } else {
    timeFlag = false;
  }

  return timeFlag;
};
