export const data = {
  menu: [
    {
      category: "Appetizers",
      items: [
        {
          number: 1,
          name: "Spring Rolls (2)",
          chineseName: "春卷 (2)",
          price: 5.99,
        },
        {
          number: 2,
          name: "Egg Rolls (2)",
          chineseName: "蛋卷 (2)",
          price: 7.99,
        },
        {
          number: 3,
          name: "Grilled Pork & Vegetable Dumplings (10)",
          chineseName: "鍋貼 (10)",
          price: 15.99,
        },
        {
          number: 4,
          name: "Deep Fried Chicken Wings (8)",
          chineseName: "炸鸡翅 (8)",
          price: 15.99,
        },
        {
          number: 5,
          name: "BBQ Pork",
          chineseName: "叉烧",
          price: 17.99,
        },
        {
          number: "5B",
          name: "Dry Spareribs",
          chineseName: "干骨",
          price: 15.99,
        },
        {
          number: 6,
          name: "Deep Fried Wonton with Sweet & Sour Sauce",
          chineseName: "炸雲吞",
          price: 10.99,
        },
        {
          number: 7,
          name: "Crispy Chicken",
          chineseName: "炸子鸡",
          price: 20.99,
          large: 39.99,
          sizes: true,
        },
      ],
    },
    {
      category: "Soup",
      items: [
        {
          number: 8,
          name: "Wonton Soup",
          chineseName: "云吞",
          price: 9.99,
          large: 12.99,
          sizes: true,
        },
        {
          number: 9,
          name: "Wor Wonton Soup",
          chineseName: "窝云",
          price: 12.99,
          large: 15.99,
          sizes: true,
        },
        {
          number: 10,
          name: "Hot & Sour Soup",
          chineseName: "酸辣汤",
          price: 9.99,
          large: 12.99,
          sizes: true,
          spicy: true,
        },
        {
          number: 11,
          name: "Sweet Corn Soup with Mince Chicken",
          chineseName: "鸡茸粟米汤",
          price: 9.99,
          large: 12.99,
          sizes: true,
        },
        {
          number: 12,
          name: "Chicken Noodle Soup",
          chineseName: "大鸡面",
          price: 14.99,
        },
      ],
    },
    {
      category: "Fish Fillet (Basa)",
      items: [
        {
          number: 13,
          name: "Sweet & Sour Fish",
          chineseName: "糖醋鱼",
          price: 17.99,
        },
        {
          number: 14,
          name: "Salt & Pepper Fish",
          chineseName: "椒盐鱼",
          price: 17.99,
        },
        {
          number: 15,
          name: "Lemon Basa Fish",
          chineseName: "柠檬鱼",
          price: 17.99,
        },
        {
          number: 16,
          name: "Palace Style Fish",
          chineseName: "宫保鱼",
          price: 17.99,
        },
        {
          number: 17,
          name: "Szechuan Style Fish",
          chineseName: "四川鱼",
          price: 17.99,
          spicy: true,
        },
      ],
    },
    {
      category: "Seafood",
      items: [
        {
          number: 18,
          name: "Salt & Pepper Squid",
          chineseName: "椒盐鱿鱼",
          price: 18.99,
          spicy: true,
        },
        {
          number: 19,
          name: "Salt & Pepper 3 Kinds of Seafood",
          chineseName: "椒盐三鲜",
          price: 20.99,
          spicy: true,
        },
        {
          number: 20,
          name: "Salt & Pepper Prawns & Scallops",
          chineseName: "椒盐二鲜",
          price: 21.99,
          spicy: true,
        },
        {
          number: 21,
          name: "Salt & Pepper Prawns",
          chineseName: "椒盐虾",
          price: 19.99,
          spicy: true,
        },
        {
          number: 22,
          name: "Szechuan Style Prawns",
          chineseName: "四川虾",
          price: 19.99,
          spicy: true,
        },
        {
          number: 23,
          name: "Deep Fried Shrimp",
          chineseName: "炸虾仔",
          price: 19.99,
        },
        {
          number: 24,
          name: "Palace Style Prawns",
          chineseName: "宫保虾",
          price: 19.99,
          spicy: true,
        },
        {
          number: 25,
          name: "Sweet & Sour Prawns with Pineapple",
          chineseName: "糖醋虾",
          price: 19.99,
        },
        {
          number: 26,
          name: "Prawns & Broccoli",
          chineseName: "西兰花虾",
          price: 19.99,
        },
        {
          number: 27,
          name: "Prawns with Snow Peas",
          chineseName: "雪豆虾",
          price: 19.99,
        },
        {
          number: 28,
          name: "Prawns with Scallop & Snow Peas",
          chineseName: "雪豆带子虾",
          price: 19.99,
        },
        {
          number: 29,
          name: "Shrimp, Scallops & Squid",
          chineseName: "油爆三鲜",
          price: 19.99,
        },
        {
          number: 30,
          name: "Prawns in Black Bean Sauce",
          chineseName: "豉汁虾",
          price: 19.99,
        },
        {
          number: 31,
          name: "Prawns in Fresh Tomatoes",
          chineseName: "番茄虾",
          price: 19.99,
        },
        {
          number: 32,
          name: "Prawns in Curry Sauce",
          chineseName: "咖喱虾",
          price: 19.99,
          spicy: true,
        },
        {
          number: 33,
          name: "Prawns in Vegetables",
          chineseName: "什菜虾",
          price: 19.99,
        },
      ],
    },
    {
      category: "Chicken",
      items: [
        {
          number: 34,
          name: "Salt & Pepper Chicken Wings (8)",
          chineseName: "椒盐鸡翅 (8)",
          price: 16.99,
          spicy: true,
        },
        {
          number: 35,
          name: "Honey Garlic Chicken Wings (8)",
          chineseName: "蜜糖鸡翅 (8)",
          price: 16.99,
        },
        {
          number: 36,
          name: "Ginger Fried Chicken",
          chineseName: "干鸡",
          price: 17.99,
          spicy: true,
        },
        {
          number: 37,
          name: "Salt & Pepper Chicken",
          chineseName: "椒鸡",
          price: 17.99,
          spicy: true,
        },
        {
          number: 38,
          name: "Boneless Lemon Chicken (So Gai)",
          chineseName: "柠檬鸡",
          price: 17.99,
        },
        {
          number: 39,
          name: "Boneless Almond Chicken (So Gai)",
          chineseName: "杏酥鸡",
          price: 17.99,
        },
        {
          number: 40,
          name: "Sweet & Sour Chicken Balls",
          chineseName: "鸡菠",
          price: 17.99,
        },
        {
          number: 41,
          name: "Sweet & Sour Chicken with Pineapple",
          chineseName: "古鸡",
          price: 17.99,
        },
        {
          number: 42,
          name: "Palace Style Chicken (Kung Pao)",
          chineseName: "宫保鸡",
          price: 17.99,
          spicy: true,
        },
        {
          number: 43,
          name: "Chicken with Broccoli",
          chineseName: "西兰花鸡",
          price: 16.99,
        },
        {
          number: 44,
          name: "Chicken with Snowpeas",
          chineseName: "雪豆鸡",
          price: 16.99,
        },
        {
          number: 45,
          name: "Chicken with Mixed Vegetables",
          chineseName: "杂菜鸡",
          price: 16.99,
        },
        {
          number: 46,
          name: "Teriyaki Chicken",
          chineseName: "日本鸡",
          price: 16.99,
        },
        {
          number: 47,
          name: "Szechuan Style Chicken",
          chineseName: "四川鸡",
          price: 16.99,
          spicy: true,
        },
        {
          number: 48,
          name: "Chicken & Prawns in Honey Pepper Sauce",
          chineseName: "铁龙凤",
          price: 17.99,
        },
        {
          number: 49,
          name: "Diced Chicken & Cashews in Yellow Bean Sauce",
          chineseName: "酱鸡",
          price: 16.99,
        },
        {
          number: 50,
          name: "Diced Chicken with Cashews",
          chineseName: "腰果鸡丁",
          price: 16.99,
        },
        {
          number: 51,
          name: "Diced Chicken with Almonds",
          chineseName: "杏仁鸡丁",
          price: 16.99,
        },
        {
          number: 52,
          name: "Diced Chicken in Hot Sauce",
          chineseName: "红油鸡丁",
          price: 16.99,
          spicy: true,
        },
        {
          number: 53,
          name: "Chicken with Black Bean Sauce",
          chineseName: "豉汁鸡",
          price: 16.99,
        },
        {
          number: 54,
          name: "Chicken with Black Pepper Sauce",
          chineseName: "黑椒鸡",
          price: 16.99,
          spicy: true,
        },
        {
          number: 55,
          name: "Chicken with Two Kinds of Mushrooms",
          chineseName: "双菇鸡",
          price: 16.99,
        },
        {
          number: 56,
          name: "Chicken with Mushrooms",
          chineseName: "菇鸡",
          price: 16.99,
        },
        {
          number: 57,
          name: "Chicken with Curry Sauce",
          chineseName: "咖喱鸡",
          price: 16.99,
          spicy: true,
        },
      ],
    },
    {
      category: "Beef",
      items: [
        {
          number: 58,
          name: "Ginger Fried Beef",
          chineseName: "干牛丝",
          price: 18.99,
          spicy: true,
        },
        {
          number: 59,
          name: "Beef with Snow Peas",
          chineseName: "雪豆牛",
          price: 17.99,
        },
        {
          number: 60,
          name: "Beef with Broccoli",
          chineseName: "百加利牛",
          price: 17.99,
        },
        {
          number: 61,
          name: "Beef with Mixed Vegetables",
          chineseName: "杂菜牛",
          price: 17.99,
        },
        {
          number: 62,
          name: "Beef with Mushrooms & Baby Corn",
          chineseName: "双冬牛片",
          price: 17.99,
        },
        {
          number: 63,
          name: "Beef with Gai Lan",
          chineseName: "介兰牛",
          price: 17.99,
        },
        {
          number: 64,
          name: "Beef with Fresh Mushrooms",
          chineseName: "蘑菇牛",
          price: 17.99,
        },
        {
          number: 65,
          name: "Beef with Black Pepper Sauce",
          chineseName: "黑椒牛柳",
          price: 17.99,
          spicy: true,
        },
        {
          number: 66,
          name: "Beef with Black Bean Sauce",
          chineseName: "豉汁牛",
          price: 17.99,
        },
        {
          number: 67,
          name: "Beef with Satay Sauce",
          chineseName: "沙爹牛",
          price: 17.99,
          spicy: true,
        },
        {
          number: 68,
          name: "Beef with Green Beans & Black Bean Sauce",
          chineseName: "豉汁豆仔牛肉",
          price: 17.99,
        },
        {
          number: 69,
          name: "Beef with Curry Sauce",
          chineseName: "咖喱牛",
          price: 17.99,
          spicy: true,
        },
        {
          number: "70B",
          name: "Szechuan Beef",
          chineseName: "西川牛",
          price: 17.99,
          spicy: true,
        },
      ],
    },
    {
      category: "Pork & Spareribs",
      items: [
        {
          number: 70,
          name: "Sweet & Sour Pork",
          chineseName: "古肉",
          price: 17.99,
        },
        {
          number: 71,
          name: "Sweet & Sour Pork Chops",
          chineseName: "京都排",
          price: 17.99,
        },
        {
          number: 72,
          name: "Salt & Pepper Pork Chops",
          chineseName: "椒猪排",
          price: 17.99,
          spicy: true,
        },
        {
          number: 73,
          name: "BBQ Pork with Snowpeas",
          chineseName: "雪豆叉烧",
          price: 17.99,
        },
        {
          number: 74,
          name: "Szechuan Shredded Pork",
          chineseName: "鱼香肉丝",
          price: 17.99,
          spicy: true,
        },
        {
          number: 75,
          name: "BBQ Pork, Shirmps, Vegetables in Yellow Bean Sauce",
          chineseName: "八宝",
          price: 17.99,
        },
        {
          number: 76,
          name: "Salt & Pepper Spareribs",
          chineseName: "椒盐骨",
          price: 16.99,
          spicy: true,
        },
        {
          number: 77,
          name: "Honey Garlic Spareribs",
          chineseName: "蜜骨",
          price: 16.99,
          spicy: true,
        },
        {
          number: 78,
          name: "Sweet & Sour Spareribs",
          chineseName: "甜骨",
          price: 16.99,
          spicy: true,
        },
      ],
    },
    {
      category: "Chop Suey",
      items: [
        {
          number: 79,
          name: "Shrimp Chop Suey",
          chineseName: "虾杂碎",
          price: 15.99,
        },
        {
          number: 80,
          name: "Chicken Chop Suey",
          chineseName: "鸡杂碎",
          price: 15.99,
        },
        {
          number: 81,
          name: "Beef Chop Suey",
          chineseName: "牛杂碎",
          price: 15.99,
        },
        {
          number: 82,
          name: "BBQ Pork Chop Suey",
          chineseName: "叉烧碎",
          price: 15.99,
        },
        {
          number: 83,
          name: "Mushroom Chop Suey",
          chineseName: "毛菇碎",
          price: 15.99,
        },
        {
          number: 84,
          name: "Mixed Vegetable Chop Suey",
          chineseName: "杂菜杂碎",
          price: 15.99,
        },
      ],
    },
    {
      category: "Mu Shu",
      info: "Stir fried with egg, served with hoi sin sauce & 8 pancakes",
      items: [
        {
          number: 85,
          name: "Mu Shu Shrimps",
          chineseName: "木须虾 (8 饼酱)",
          price: 19.99,
        },
        {
          number: 86,
          name: "Mu Shu Chicken",
          chineseName: "木须鸡 (8 饼酱)",
          price: 19.99,
        },
        {
          number: 87,
          name: "Mu Shu Pork",
          chineseName: "木须肉 (8 饼酱)",
          price: 19.99,
        },
        {
          number: 88,
          name: "Mu Shu Beef",
          chineseName: "木须牛 (8 饼酱)",
          price: 19.99,
        },
        {
          number: 89,
          name: "Mu Shu Mixed Vegetables",
          chineseName: "木须菜 (8 饼酱)",
          price: 19.99,
        },
        {
          number: "89B",
          name: "Extra Pancakes",
          chineseName: "8 饼酱",
          price: 3.0,
        },
      ],
    },

    {
      category: "Egg Foo Yung",
      items: [
        {
          number: 90,
          name: "Shrimp Egg Foo Yung",
          chineseName: "虾芙",
          price: 18.99,
        },
        {
          number: 91,
          name: "Chicken Egg Foo Yung",
          chineseName: "鸡芙",
          price: 18.99,
        },
        {
          number: 92,
          name: "Beef Egg Foo Yung",
          chineseName: "牛芙",
          price: 18.99,
        },
        {
          number: 93,
          name: "BBQ Pork Egg Foo Yung",
          chineseName: "叉烧芙",
          price: 18.99,
        },
        {
          number: 94,
          name: "Mixed Vegetable Egg Foo Yung",
          chineseName: "杂菜芙",
          price: 18.99,
        },
      ],
    },
    {
      category: "Vegetables",
      items: [
        {
          number: 95,
          name: "Stir Fried Mixed Vegetable",
          chineseName: "什菜",
          price: 15.99,
        },
        {
          number: 96,
          name: "Four Treasures wtih Light Oyster Sauce",
          desc: "(Mushrooms, Peapod, Broccoli, Baby Corn)",
          chineseName: "四素",
          price: 15.99,
        },
        {
          number: 97,
          name: "Deep Fried French Fries",
          chineseName: "炸薯条",
          price: 9.99,
        },
        {
          number: 98,
          name: "Mixed Vegetables in Curry Sauce",
          chineseName: "咖喱杂菜",
          price: 15.99,
          spicy: true,
        },
        {
          number: 99,
          name: "Salt & Pepper Tofu",
          chineseName: "椒盐豆腐",
          price: 15.99,
          spicy: true,
        },
        {
          number: 100,
          name: "Fried Bean Curd with Vegetables",
          chineseName: "杂菜豆腐",
          price: 15.99,
        },
        {
          number: 101,
          name: "Szechuan Style Eggplant",
          chineseName: "鱼香茄",
          price: 15.99,
          spicy: true,
        },
        {
          number: "101A",
          name: "Mixed Vegatables with Cashews",
          chineseName: "腰果杂菜",
          price: 15.99,
        },
      ],
    },
    {
      category: "Chow Mein",
      items: [
        {
          number: 102,
          name: "Chicken Chow Mein in Soy Sauce",
          chineseName: "酱油炒面",
          price: 16.99,
        },
        {
          number: 103,
          name: "Cantonese Crispy Chow Mein with 3 Kinds of Seafood",
          chineseName: "海鲜炒面",
          price: 18.99,
        },
        {
          number: 104,
          name: "Cantonese Crispy Chicken Chow Mein",
          chineseName: "鸡炒面",
          price: 17.99,
        },
        {
          number: 105,
          name: "Cantonese Crispy Beef Chow Mein",
          chineseName: "牛炒面",
          price: 17.99,
        },
        {
          number: 106,
          name: "Cantonese Crispy Mixed Meat Chow Mein",
          chineseName: "广东炒面",
          price: 17.99,
        },
        {
          number: 107,
          name: "Cantonese Crispy BBQ Pork Chow Mein",
          chineseName: "叉烧炒面",
          price: 17.99,
        },
        {
          number: "107B",
          name: "Cantonese Crispy Chow Mein in Black Bean Sauce",
          chineseName: "豉汁炒面",
          price: 17.99,
        },
        { 
          number: 108,
          name: "Cantonese Crispy Mixed Vegetables Chow Mein",
          chineseName: "杂菜炒面",
          price: 17.99,
        },
        {
          number: 109,
          name: "Cantonese Crispy Beef Chow Mein in Satay Sauce",
          chineseName: "沙爹牛炒面",
          price: 17.99,
          spicy: true,
        },
        {
          number: 110,
          name: "Signapore Style Rice Noodles",
          desc: "(Rice Vermicelli)",
          chineseName: "星米",
          price: 17.99,
          spicy: true,
        },
      ],
    },
    {
      category: "Noodles",
      items: [
        {
          number: 111,
          name: "Shanghai Noodles with Seafood",
          chineseName: "海鮮上麵",
          price: 17.99,
        },
        {
          number: 112,
          name: "Shanghai Noodles with Beef & Vegetables",
          chineseName: "牛上麵",
          price: 15.99,
        },
        {
          number: 113,
          name: "Shanghai Noodles with Chicken & Vegetables",
          chineseName: "鸡上麵",
          price: 15.99,
        },
        {
          number: 114,
          name: "Shanghai Noodles with BBQ Pork & Vegetables",
          chineseName: "叉烧上麵",
          price: 15.99,
          spicy: true,
        },
        {
          number: 115,
          name: "Szechuan Noodles with Chicken & Shrimp",
          chineseName: "鸡蝦湖南麵",
          price: 15.99,
          spicy: true,
        },
        {
          number: 116,
          name: "Shanghai Noodles with Shredded Pork",
          chineseName: "上麵",
          price: 15.99,
        },
        {
          number: 117,
          name: "Shanghai Noodles with Mixed Vegetables",
          chineseName: "杂菜粗",
          price: 15.99,
        },
        {
          number: 118,
          name: "Beef Fried Ho Fun with Bean Sprout",
          chineseName: "牛肉河粉",
          price: 17.99,
        },
        {
          number: 119,
          name: "Beef Fried Ho Fun with Black Bean Sauce",
          chineseName: "豉汁牛河粉",
          price: 17.99,
        },
      ],
    },
    {
      category: "Fried Rice",
      items: [
        {
          number: 120,
          name: "Shrimp Fried Rice",
          chineseName: "虾仁炒饭",
          price: 15.99,
        },
        {
          number: 121,
          name: "Yang Chow Fried Rice",
          chineseName: "扬州炒饭",
          price: 15.99,
        },
        {
          number: 122,
          name: "Chicken Fried Rice",
          chineseName: "鸡炒饭",
          price: 15.99,
        },
        {
          number: 123,
          name: "BBQ Pork Fried Rice",
          chineseName: "叉烧炒饭",
          price: 15.99,
        },
        {
          number: 124,
          name: "Beef Fried Rice",
          chineseName: "牛肉炒饭",
          price: 15.99,
        },
        {
          number: 125,
          name: "Hunan Fried Rice with BBQ Pork & Pineapple",
          chineseName: "湖南炒饭",
          price: 15.99,
          spicy: true,
        },
        {
          number: 126,
          name: "Mushroom Fried Rice",
          chineseName: "蘑菇炒饭",
          price: 14.99,
        },
        {
          number: 127,
          name: "Mixed Vegetable Fried Rice",
          chineseName: "杂菜炒饭",
          price: 14.99,
        },
        {
          number: 128,
          name: "Steamed Rice (Per Person)",
          chineseName: "白饭",
          price: 4.0,
        },
      ],
    },
    {
      category: "Desserts",
      items: [
        {
          number: 129,
          name: "Mango Pudding (2)",
          chineseName: "芒果布丁 (2)",
          price: 3.95,
        },
        {
          number: 130,
          name: "Wonton Dipped in Sugar Coating",
          chineseName: "馄饨蘸糖衣",
          price: 7.99,
          large: 10.99,
          sizes: true,
        },
      ],
    },
  ],
};
