import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from "@chakra-ui/react";

function ConfirmModal(props) {
  const initialRef = React.useRef(null);

  const [error, setError] = useState(false);

  const toggleConfirmOrder = () => {
    let tempCustomerOrders = [...props.customerOrders];
    tempCustomerOrders[props.index].confirmed = true;

    props.setConfirm(tempCustomerOrders);
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      initialFocusRef={initialRef}
      size="xl"
      isCentered
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Confirm Order For:{" "}
          {props.customerOrder !== null ? props.customerOrder.data.name : null}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {props.customerOrder !== null ? (
            props.customerOrder.data.time === "ASAP" ? (
              <FormControl>
                <FormLabel>Enter Time (minutes)</FormLabel>
                <Input
                  isInvalid={error ? true : false}
                  ref={initialRef}
                  type="number"
                  onChange={e => {
                    props.setTime(e.target.value);
                  }}
                />
                <FormHelperText>Example: 15</FormHelperText>
              </FormControl>
            ) : (
              <Text>
                Picking up at:{" "}
                <i>
                  <b>{props.customerOrder.data.time}</b>
                </i>
              </Text>
            )
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => {
              toggleConfirmOrder();

              if (props.customerOrder.data.time === "ASAP") {
                if (props.orderTime === undefined) {
                  setError(true);
                } else {
                  setError(false);
                  props.sendToAdmin();
                  props.onClose();
                }
              } else {
                props.sendToAdmin();
                props.onClose();
              }
            }}
            colorScheme="green"
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;
