import React from "react";
import {
  Flex,
  Text,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  VStack,
  HStack,
  TableContainer,
} from "@chakra-ui/react";

import { BsClock, BsPersonSquare, BsCalendar } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import moment from "moment";
moment().format();
function CustomerOrder(props) {
  const today = moment().format("hh:mmA");
  const date = moment().format("MMMM, D YYYY");

  return (
    <Flex
      mt="1rem"
      mb="1rem"
      border="1px"
      borderRadius="4px"
      borderColor="#c4c4c4"
      direction="column"
      bg="white"
    >
      <Text alignSelf="flex-end" mr="2rem" mt="0.5rem">
        {date}
      </Text>
      <Flex alignSelf="center" mt="1rem" direction="column" align="center">
        <Text fontWeight={700} fontSize="22px">
          Gold Bistro Chinese Restaurant
        </Text>
        <Text>#2 - 208 Midpark Way SE, Calgary AB</Text>
        <Text>403-254-6908</Text>
        <Text>GST# BN 899233431</Text>
      </Flex>
      {props.customerOrders.length > 0 &&
        props.customerOrders.map((orders, i) => {
          let subTotal = orders.data.subTotal;
          let extraCharge = orders.data.additionalCharges;
          let gst;
          let discount;
          let discountPercentage = orders.data.discountInfo.percentage;

          //addtional charges is added to subtotal
          if (orders.data.hasOwnProperty("additionalCharges")) {
            subTotal = parseFloat(subTotal) + parseFloat(extraCharge);
          }
          discount = parseFloat(subTotal) * discountPercentage;

          gst = (parseFloat(subTotal) - parseFloat(discount)) * 0.05;

          return orders.toggle ? (
            <Flex key={"order-details" + i} direction="column" bg="white">
              <Flex direction="column" m="2rem">
                <Flex direction="column" justify="space-between">
                  <Text fontSize="20px" mb="1rem" ml="1rem">
                    Order #{props.customerOrders.length - i}
                  </Text>

                  <HStack justify="space-between" ml="1rem" mr="1rem">
                    <Flex align="center">
                      <Icon w={5} h={5} as={BsPersonSquare} />
                      <Text ml="0.5rem">{orders.data.name}</Text>
                    </Flex>
                    <Flex align="center">
                      <Icon w={5} h={5} as={AiOutlineMail} />
                      <Text ml="0.5rem">{orders.data.email}</Text>
                    </Flex>

                    <Flex align="center">
                      <Icon w={5} h={5} as={BiPhone} />
                      <Text ml="0.5rem">{orders.data.phone}</Text>
                    </Flex>

                    <Flex align="center">
                      <Icon w={5} h={5} as={BsCalendar} />
                      <Text ml="0.5rem">{today}</Text>
                    </Flex>

                    <Flex align="center">
                      <Icon w={5} h={5} as={BsClock} />
                      <Text ml="0.5rem"> {orders.data.time}</Text>
                    </Flex>
                  </HStack>
                </Flex>

                <TableContainer mt="2rem">
                  <Table size="sm" variant="striped" colorScheme="black">
                    <Thead>
                      <Tr>
                        <Th color="black">Number</Th>
                        <Th color="black">Item</Th>
                        <Th color="black">Note</Th>
                        <Th color="black">Qty</Th>
                        <Th color="black">Price</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orders.data.cart.map((cartItem, i) => {
                        if (cartItem.combo) {
                          return (
                            <Tr key={"customer-combo-item" + i}>
                              <Th color="black">{cartItem.number}</Th>
                              <Th color="black" textTransform="capitalize">
                                <Flex direction="column">
                                  {cartItem.items.map((comboItem, i) => {
                                    return (
                                      <Text
                                        mb="5px"
                                        key={"combo-item-name" + i}
                                      >
                                        {comboItem}
                                      </Text>
                                    );
                                  })}
                                </Flex>
                              </Th>
                              <Th
                                color="black"
                                whiteSpace="pre-wrap"
                                width="40%"
                                textTransform="capitalize"
                              >
                                <VStack align="flex-start">
                                  <Text>
                                    {["D1", "D2"].includes(cartItem.number)
                                      ? ""
                                      : cartItem.soup}
                                  </Text>
                                  <Text>{cartItem.note}</Text>
                                </VStack>
                              </Th>
                              <Th color="black">{cartItem.quantity}</Th>
                              <Th color="black">
                                {(cartItem.price * cartItem.quantity).toFixed(
                                  2
                                )}
                              </Th>
                            </Tr>
                          );
                        } else {
                          return (
                            <Tr key={"customer-item" + i}>
                              <Th color="black">{cartItem.number}</Th>
                              <Th color="black" textTransform="capitalize">
                                <Flex direction="column">
                                  <Text>{cartItem.name}</Text>
                                  <Text mt="5px">{cartItem.chineseName}</Text>
                                </Flex>
                              </Th>
                              <Th
                                color="black"
                                whiteSpace="pre-wrap"
                                width="40%"
                                textTransform="capitalize"
                              >
                                {cartItem.note}
                                {cartItem.sizes ? cartItem.itemSize : null}
                              </Th>
                              <Th color="black">{cartItem.quantity}</Th>
                              <Th color="black">
                                {(cartItem.price * cartItem.quantity).toFixed(
                                  2
                                )}
                              </Th>
                            </Tr>
                          );
                        }
                      })}

                      {orders.data.discountInfo.item !== "none" &&
                      discountPercentage === 0 ? (
                        <Tr>
                          <Th color="black">**</Th>
                          <Th color="black" textTransform="capitalize">
                            <VStack align="flex-start">
                              <Text>{orders.data.discountInfo.item}</Text>
                              <Text>
                                {orders.data.discountInfo.chineseName}
                              </Text>
                            </VStack>
                          </Th>

                          <Th color="black"></Th>
                          <Th color="black">1</Th>
                          <Th color="black">Free</Th>
                        </Tr>
                      ) : null}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Flex direction="column" mt="3rem" ml="2rem">
                  {orders.data.additionalNotes !== "" ? (
                    <Text fontWeight={700}>Additional Notes:</Text>
                  ) : null}
                  <Text mt="1rem">{orders.data.additionalNotes}</Text>
                </Flex>
                <Flex m="2rem" justify="flex-end" fontSize="14px">
                  <Flex align="flex-start" direction="column" fontWeight={700}>
                    <Text>
                      Subtotal:{" $"}
                      {parseFloat(orders.data.subTotal).toFixed(2)}
                    </Text>
                    {extraCharge && (
                      <Text>
                        Charges:
                        {parseFloat(extraCharge) < 0
                          ? " -$" + parseFloat(extraCharge * -1).toFixed(2)
                          : " +$" + parseFloat(extraCharge).toFixed(2)}
                      </Text>
                    )}
                    <Text>
                      {discountPercentage === 0
                        ? "Discount: $"
                        : `Discount (${
                            parseFloat(discountPercentage) * 100
                          }%): -$`}
                      {discount.toFixed(2)}
                    </Text>
                    <Text>
                      GST:{" $"}
                      {gst.toFixed(2)}
                    </Text>
                    <Text>
                      Total:{" $"}
                      {(parseFloat(subTotal) - discount + gst).toFixed(2)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ) : null;
        })}
    </Flex>
  );
}

export default CustomerOrder;
