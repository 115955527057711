import { Flex, Text, Spinner } from "@chakra-ui/react";
import React from "react";

function Loading(props) {
  return (
    <Flex width="100%" direction="column" align="center">
      {props.confirm ? (
        <Flex
          direction="column"
          fontSize={{ base: "24px", md: "32px" }}
          mr="1rem"
          align="center"
          fontWeight={400}
          width={{ base: "100%", md: "80%" }}
        >
          <Text textAlign="center">Thank you</Text>
          <Text textAlign="center" mt="2rem">
            Your order is confirmed! A detailed order summary will be sent to
            your email including the pick up time.
          </Text>
          <Text textAlign="center" mt="2rem" fontSize="24px">
            *Make sure to check your spam/junk folder if you do not see the
            email
          </Text>
        </Flex>
      ) : (
        <>
          <Text
            fontWeight={400}
            fontSize={{ base: "24px", md: "32px" }}
            mr="1rem"
          >
            Please wait while we confirm your order!
          </Text>
          <Spinner
            mt="3rem"
            mb="3rem"
            thickness="2px"
            speed="0.85s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </>
      )}
    </Flex>
  );
}

export default Loading;
