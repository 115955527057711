import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Flex, Text, SimpleGrid } from "@chakra-ui/react";
import Combo from "./Combo";

import "../../App.css";
import {
  Link,
  Element,
  Events,
  scrollSpy,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

import { data } from "../../data/menu";

import CartBody from "./CartBody";
import ItemButton from "./ItemButton";
import Misc from "./Misc";
import { navMenuList } from "../../data/menunav";

function Itemnav(props) {
  //https://stackoverflow.com/questions/67673357/check-if-an-element-is-in-the-viewport-but-with-mapped-refs-reactjs

  const [navMenu, setNavMenu] = useState(navMenuList);

  let oldScrollY = 0;
  const [direction, setDirection] = useState("up");

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      setDirection("down");
    } else {
      setDirection("up");
    }
    oldScrollY = window.scrollY;
  };

  //itemnav ref to update scroll position
  const ref = useRef(null);

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {
      //console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      //console.log("end", arguments);
    });
    window.addEventListener("scroll", controlDirection);

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
      window.removeEventListener("scroll", controlDirection);
    };
  }, []);

  const moveXBar = dir => {
    const offset = 210;

    if (dir === "right") {
      ref.current.scrollLeft += offset;
    } else {
      ref.current.scrollLeft -= offset;
    }
  };

  //function to move each category left or right by its bounding width
  const moveMenu = (category, tag, index) => {
    //console.log(category, tag, index);
    if (ref.current != null) {
      if (index > 0 && direction === "down") {
        let categoryWidthRight = ref.current.children[index - 1].clientWidth;
        ref.current.scrollLeft += categoryWidthRight;
      } else if (direction === "up") {
        let categoryWidthLeft = ref.current.children[index].clientWidth;
        ref.current.scrollLeft -= categoryWidthLeft;
      }
    }
  };

  return (
    <Flex direction="column" mt="3rem">
      <Text fontSize="24px" fontFamily="innerbody" fontWeight={700}>
        FULL MENU
      </Text>
      <Flex
        bg="white"
        opacity="100%"
        height="100%"
        position="sticky"
        top="6rem"
        pb="1.5rem"
        pt="1.5rem"
        zIndex={2}
        align="center"
      >
        <Flex
          p="6px"
          _hover={{ cursor: "pointer" }}
          onClick={() => moveXBar("left")}
        >
          <ArrowLeftIcon />
        </Flex>
        <Flex
          ref={ref}
          className="horizontal-scrollbar"
          overflowX="scroll"
          mr={{ base: "0rem", lg: "0.7rem" }}
          ml={{ base: "0rem", lg: "0.7rem" }}
          scrollBehavior="smooth"
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {navMenu.map((item, i) => {
            return (
              <Link
                key={"key" + i}
                activeClass="active"
                className="category-links"
                to={item.category}
                spy={true}
                smooth={true}
                duration={500}
                offset={-185}
                onSetActive={(e, k) => {
                  moveMenu(e, k, i);
                }}
              >
                <Flex
                  width="max-content"
                  pl="1rem"
                  pr="1rem"
                  pt="0.3rem"
                  pb="0.3rem"
                  whiteSpace="nowrap"
                >
                  <Text textAlign="center">{item.category}</Text>
                </Flex>
              </Link>
            );
          })}
        </Flex>
        <Flex
          p="6px"
          _hover={{ cursor: "pointer" }}
          onClick={() => moveXBar("right")}
        >
          <ArrowRightIcon />
        </Flex>
      </Flex>

      <Flex mt="4rem">
        <Flex
          direction="column"
          width={{ base: "100%", lg: "73%" }}
          className="scroll-container"
        >
          {data.menu.map((item, i) => {
            return (
              <Flex
                mb="2rem"
                key={"category: " + i}
                direction="column"
                height="100%"
              >
                <Element name={item.category} className="Element" id={"e" + i}>
                  <Text fontFamily="innerbody" fontWeight={700} fontSize="24px">
                    {item.category}
                  </Text>
                  <Text fontFamily="innerbody">{item.info}</Text>

                  <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    spacing={4}
                    mt="1rem"
                  >
                    {item.items.map((item, i) => {
                      return (
                        <ItemButton key={"item: " + i} item={item}></ItemButton>
                      );
                    })}
                  </SimpleGrid>
                </Element>
              </Flex>
            );
          })}
          <Combo></Combo>
          <Misc></Misc>
        </Flex>

        <Flex
          direction="column"
          flex={1}
          display={{ base: "none", lg: "flex" }}
          ml="3rem"
        >
          <CartBody></CartBody>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Itemnav;
