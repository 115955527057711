import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Icon,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  RadioGroup,
  VStack,
  Radio,
} from "@chakra-ui/react";

import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { maintenance, message } from "../../data/maintenance";
import { CartContext } from "../../context/CartContext";

function Popup(props) {
  const { cart, setCart, subTotal, setSubTotal } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);
  const [note, setNote] = useState("");
  const [size, setSize] = useState("small");
  const [soup, setSoup] = useState("Wonton Soup");

  const minus = () => {
    if (quantity !== 1) {
      setQuantity(quantity - 1);
    }
  };

  const add = () => {
    if (quantity < 9) {
      setQuantity(quantity + 1);
    }
  };

  const noteHandler = e => {
    setNote(e.target.value);
  };

  const updatePrice = (e, price) => {
    if (e.target.value === "large") {
      setSize(e.target.value);
    } else {
      setSize("small");
    }
  };

  const updateSoup = soup => {
    if (soup === "Hot & Sour Soup") {
      setSoup("Hot & Sour Soup");
    } else {
      setSoup("Wonton Soup");
    }
  };

  const addToCart = item => {
    const tempCart = [...cart];
    let price;
    if (size === "large") {
      price = item.large;
    } else {
      price = item.price;
    }

    const itemObj = {
      name: item.name,
      number: item.number,
      price: price,
      items: item.items,
      quantity: quantity,
      soup: soup,
      itemSize: size,
      sizes: item.sizes,
      note: note,
      combo: item.combo,
      chineseName: item.chineseName,
    };
    tempCart.push(itemObj);

    let itemTotal = parseFloat(itemObj.price * itemObj.quantity).toFixed(2);
    setSubTotal((parseFloat(subTotal) + parseFloat(itemTotal)).toFixed(2));

    setCart(tempCart);
    setSoup("Wonton Soup");
    setQuantity(1);
    setSize("small");
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        setQuantity(1);
        setSoup("Wonton Soup");
        setSize("small");
        props.onClose();
      }}
      isCentered
      size="xl"
      blockScrollOnMount
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent
        ml={{ base: "1rem", md: "0rem" }}
        mr={{ base: "1rem", md: "0rem" }}
      >
        {maintenance() ? (
          <ModalHeader width="90%">
            <Text>{message()}</Text>
          </ModalHeader>
        ) : (
          <ModalHeader width="90%">
            {props.item.number
              ? props.item.number + ". " + props.item.name
              : props.item.name}
          </ModalHeader>
        )}
        <ModalCloseButton />

        <ModalBody>
          {maintenance() ? null : (
            <FormControl>
              {props.item.sizes ? (
                <>
                  <FormLabel htmlFor="size">Choose Size</FormLabel>
                  <RadioGroup defaultValue="small">
                    <VStack align="flex-start">
                      <Radio
                        colorScheme="red"
                        value="small"
                        onChange={e => updatePrice(e, props.item.price)}
                      >
                        <Text fontSize="14px">
                          {props.item.number === 7
                            ? "Half - $" + props.item.price
                            : "Small - $" + props.item.price}
                        </Text>
                      </Radio>
                      <Radio
                        colorScheme="red"
                        value="large"
                        onChange={e => updatePrice(e, props.item.large)}
                      >
                        <Text fontSize="14px">
                          {props.item.number === 7
                            ? "Whole - $" + props.item.large
                            : "Large - $" + props.item.large}
                        </Text>
                      </Radio>
                    </VStack>
                  </RadioGroup>
                </>
              ) : null}
              {props.item.soup ? (
                <>
                  <Text
                    mb="0.5rem"
                    mt="-1rem"
                    fontSize="15px"
                    color="blackAlpha.600"
                  >
                    *Substituion charges will be applied by the restaurant.
                    Subtotal shown may not be final and may subject to change
                  </Text>
                  <FormLabel htmlFor="size">Choose Soup</FormLabel>
                  <RadioGroup defaultValue="wontonsoup">
                    <VStack align="flex-start">
                      <Radio
                        colorScheme="red"
                        value="wontonsoup"
                        onChange={e => updateSoup("Wonton Soup")}
                      >
                        <Text fontSize="14px">Wonton Soup</Text>
                      </Radio>
                      <Radio
                        colorScheme="red"
                        value="hot&soursoup"
                        onChange={e => updateSoup("Hot & Sour Soup")}
                      >
                        <Text fontSize="14px">Hot & Sour Soup</Text>
                      </Radio>
                    </VStack>
                  </RadioGroup>
                </>
              ) : null}

              <FormLabel htmlFor="special-instructions" mt="1rem">
                Special Instructions
              </FormLabel>
              <Textarea
                type="text"
                id="special-instructions"
                resize="none"
                maxLength="175"
                borderColor="#c4c4c4"
                onChange={e => noteHandler(e)}
              />
              <FormHelperText>Example: No Mushrooms</FormHelperText>
            </FormControl>
          )}
        </ModalBody>

        {maintenance() ? null : (
          <ModalFooter as={Flex} justifyContent="space-between">
            <Flex width="50%" align="center" justifyContent="center">
              <Flex
                align="center"
                width={{ base: "75%", md: "55%" }}
                justifyContent="space-between"
              >
                <Icon
                  w={7}
                  h={7}
                  cursor="pointer"
                  as={AiOutlineMinusCircle}
                  onClick={() => minus()}
                  opacity={quantity === 1 ? "40%" : "100%"}
                />
                <Text>{quantity}</Text>
                <Icon
                  w={7}
                  h={7}
                  cursor="pointer"
                  as={AiOutlinePlusCircle}
                  onClick={() => add()}
                  opacity={quantity === 9 ? "40%" : "100%"}
                />
              </Flex>
            </Flex>
            <Button
              width="70%"
              variant="solid"
              bg="black"
              color="white"
              _hover={{ bg: "#494949" }}
              onClick={() => {
                addToCart(props.item);
                setQuantity(1);
                props.onClose();
              }}
            >
              Add to Cart -
              {size === "small"
                ? " $" + (props.item.price * quantity).toFixed(2)
                : " $" + (props.item.large * quantity).toFixed(2)}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

export default Popup;
