export const misc = {
  drinks: [
    {
      category: "Drinks (355mL)",
      items: [
        {
          number: 131,
          name: "Coke",
          price: 2.5,
        },
        {
          number: 132,
          name: "Diet Coke",
          price: 2.5,
        },
        {
          number: 133,
          name: "Pepsi",
          price: 2.5,
        },
        {
          number: 134,
          name: "Diet Pepsi",
          price: 2.5,
        },
        {
          number: 135,
          name: "Sprite",
          price: 2.5,
        },
        {
          number: 136,
          name: "Ginger Ale",
          price: 2.5,
        },
        {
          number: 137,
          name: "Ice Tea",
          price: 2.5,
        },
        {
          number: 139,
          name: "Orange Crush",
          price: 2.5,
        },
        {
          number: 140,
          name: "Root Beer",
          price: 2.5,
        },
      ],
    },
  ],
  sauces: [
    {
      category: "Sauces",
      items: [
        { number: 141, name: "Ginger Sauce", price: 2.0 },
        { number: 142, name: "Sweet & Sour Sauce", price: 2.0 },
        { number: 143, name: "Lemon Sauce", price: 2.0 },
        { number: 144, name: "Hot Oil Sauce", price: 2.0 },
        { number: 145, name: "Hot Paste Sauce", price: 2.0 },
      ],
    },
  ],
};
