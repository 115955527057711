export const navMenuList = [
  { category: "Appetizers", toggle: false },
  { category: "Soup", toggle: false },
  { category: "Fish Fillet (Basa)", toggle: false },
  { category: "Seafood", toggle: false },
  { category: "Chicken", toggle: false },
  { category: "Beef", toggle: false },
  { category: "Pork & Spareribs", toggle: false },
  { category: "Chop Suey", toggle: false },
  { category: "Mu Shu", toggle: false },
  { category: "Egg Foo Yung", toggle: false },
  { category: "Vegetables", toggle: false },
  { category: "Chow Mein", toggle: false },
  { category: "Noodles", toggle: false },
  { category: "Fried Rice", toggle: false },
  { category: "Desserts", toggle: false },
  { category: "Set Dinners", toggle: false },
  { category: "Drinks", toggle: false },
  { category: "Sauces", toggle: false },
];
