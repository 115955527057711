import {
  Flex,
  HStack,
  Text,
  VStack,
  IconButton,
  useDisclosure,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { HamburgerIcon } from "@chakra-ui/icons";
import MobileNav from "./MobileNav";
import React from "react";
import Cart from "../order-page/Cart";
import HoverMenu from "./HoverMenu";
import { messageBannerFlag } from "../../data/maintenance";

function Navbar(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  let location = useLocation();

  return (
    <Flex
      top={messageBannerFlag ? (props.takeoutmode ? "0px" : "40px") : "0px"}
      bg="primary"
      height="100px"
      align="center"
      color="secondary"
      width={{ base: "100%", "3xl": "1500px" }}
      justify="space-between"
      zIndex={444}
      position="fixed"
      pr={{ base: "1rem", lg: "4rem" }}
      pl={{ base: "1rem", lg: "4rem" }}
    >
      <Link to="/">
        <HStack>
          <VStack
            spacing={-1}
            color="accent"
            fontFamily="chinese"
            fontSize={{ base: "18px", md: "24px" }}
            lineHeight={{ base: "25px", md: "29px" }}
            mr="0.5rem"
          >
            <Text>金</Text>
            <Text>饭</Text>
            <Text>庄</Text>
          </VStack>
          <VStack
            align="flex-start"
            spacing={0}
            fontWeight={400}
            fontFamily="heading"
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              letterSpacing={2}
              lineHeight="27px"
            >
              GOLD BISTRO
            </Text>
            <Text
              fontSize={{ base: "10px", md: "12px" }}
              letterSpacing={3.7}
              lineHeight="16px"
            >
              CHINESE CUISINE
            </Text>
          </VStack>
        </HStack>
      </Link>

      <Flex
        fontFamily="body"
        fontWeight={400}
        align="center"
        display={props.takeoutmode ? "flex" : ["none", "none", "flex", "flex"]}
      >
        {props.takeoutmode ? (
          <>
            <Flex
              ml="1rem"
              mr="1rem"
              borderBottom="1px"
              borderColor="black"
              _hover={{ borderBottom: "1px", borderColor: "white" }}
            >
              <Link to="/">HOME</Link>
            </Flex>
            {location.pathname === "/checkout" ? null : <Cart></Cart>}
          </>
        ) : (
          <>
            <Flex
              ml="1rem"
              mr="1rem"
              borderBottom="1px"
              borderColor="black"
              _hover={{ borderBottom: "1px", borderColor: "white" }}
            >
              <Link to="/">HOME</Link>
            </Flex>
            <Flex
              ml="1rem"
              mr="1rem"
              borderBottom="1px"
              borderColor="black"
              _hover={{ borderBottom: "1px", borderColor: "white" }}
            >
              <ChakraLink href="/menu.pdf" isExternal _hover={{}}>
                MENU
              </ChakraLink>
            </Flex>
            <Flex
              ml="1rem"
              mr="1rem"
              borderBottom="1px"
              borderColor="black"
              _hover={{ borderBottom: "1px", borderColor: "white" }}
            >
              <Link to="/takeout">ORDER PICKUP</Link>
            </Flex>
            <HoverMenu></HoverMenu>
          </>
        )}
      </Flex>

      {props.takeoutmode ? null : (
        <Flex display={["flex", "flex", "none", "none"]}>
          <IconButton
            color="secondary"
            aria-label="Dropdown Menu"
            variant="unstyled"
            size="lg"
            icon={<HamburgerIcon />}
            ref={btnRef}
            onClick={onOpen}
          />
          <MobileNav
            isOpen={isOpen}
            onClose={onClose}
            btnRef={btnRef}
          ></MobileNav>
        </Flex>
      )}
    </Flex>
  );
}

export default Navbar;
