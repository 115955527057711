import React, { useContext, useState } from "react";
import { CartContext } from "../../context/CartContext";
import {
  Flex,
  Divider,
  Text,
  Button,
  Icon,
  UnorderedList,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import { BsXCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { offline } from "../../data/offline";
import { holidayFlag } from "../../data/holidays";

function CartBody(props) {
  const {
    cart,
    setCart,
    discount,
    setDiscount,
    subTotal,
    setSubTotal,
    discountItems,
    setDiscountItems,
  } = useContext(CartContext);
  //console.log("cart body", cart);

  const removeItem = itemIndex => {
    //removes item from cart
    setCart(cart.filter((item, i) => itemIndex !== i));

    let removedItemPrice = cart[itemIndex].price * cart[itemIndex].quantity;

    //subtract the price of item with the total
    if (
      parseFloat(subTotal) - parseFloat(removedItemPrice) <
      parseFloat(35.0)
    ) {
      setDiscount(prevState => ({
        ...prevState,
        percentage: 0,
      }));
    }

    //everytime we remove an item, set toggle to false?
    let temp = discountItems.map((obj, i) => {
      if (i > 1) {
        return { ...obj, toggle: false };
      } else {
        return obj;
      }
    });

    setSubTotal(
      (parseFloat(subTotal) - parseFloat(removedItemPrice)).toFixed(2)
    );
    setDiscountItems(temp);
  };

  let comboTotal = 0;

  return (
    <Flex
      direction="column"
      border={{ base: "0px", lg: "1px" }}
      borderRadius="3px"
      borderColor={{ base: "none", lg: "#c4c4c4" }}
      minHeight={{ base: "100%", md: "50vh" }}
      overflowY={!props.summary ? "auto" : "none"}
      position={!props.summary ? "sticky" : "none"}
      top={!props.summary ? "12rem" : "0rem"}
      mt={{ base: "0rem", md: "3rem" }}
      mb="2rem"
      width="100%"
      zIndex={0}
    >
      <Flex
        width="100%"
        justify="center"
        mt="1rem"
        mb="1rem"
        fontFamily="body"
        fontWeight={700}
        fontSize={24}
        align="center"
        textAlign="center"
      >
        {props.checkout ? "Order Summary" : "Your Pickup Order"}
      </Flex>
      <Divider borderColor="#c4c4c4"></Divider>

      <Flex
        direction="column"
        height="100%"
        width="100%"
        justify="space-between"
      >
        <Flex direction="column">
          {cart &&
            cart.map((item, i) => {
              let itemSize =
                item.itemSize[0].toUpperCase() + item.itemSize.slice(1);

              if (item.combo) {
                comboTotal = comboTotal + item.price * item.quantity;
              }

              if (item.number === 7 && itemSize === "Small") {
                itemSize = "Half";
              } else if (item.number === 7 && itemSize === "Large") {
                itemSize = "Whole";
              }

              return (
                <Flex
                  borderBottom="1px"
                  borderColor="#c4c4c4"
                  borderRadius="3px"
                  key={"pickup-item: " + i}
                  pt="1rem"
                  pb="1rem"
                  justify="space-around"
                  fontSize="15px"
                >
                  <Flex fontWeight={700} flex={1} justify="center">
                    <Text>{item.quantity + "x"}</Text>
                  </Flex>
                  <Flex
                    direction="column"
                    width={props.checkout ? "80%" : "68%"}
                  >
                    <Flex
                      justify="space-between"
                      mr={props.checkout ? "1rem" : "0rem"}
                    >
                      <Text fontWeight={700} mr="0.6rem">
                        {item.number
                          ? item.number + ". " + item.name
                          : item.name}
                      </Text>
                      <Text>
                        {"$" +
                          parseFloat(item.price * item.quantity).toFixed(2)}
                      </Text>
                    </Flex>
                    <UnorderedList>
                      {item.sizes ? <ListItem>{itemSize}</ListItem> : null}
                    </UnorderedList>
                    <UnorderedList
                      direction="column"
                      fontWeight={400}
                      fontSize="14px"
                    >
                      {item.items &&
                        item.items.map((setItem, i) => {
                          return (
                            <ListItem key={"set-items: " + i}>
                              {setItem === "Wonton Soup OR Hot & Sour Soup"
                                ? item.soup
                                : setItem}
                            </ListItem>
                          );
                        })}
                    </UnorderedList>
                    <Flex>
                      {item.note ? (
                        <Text mt="0.5rem" fontSize="14px">
                          Note: {item.note}
                        </Text>
                      ) : null}
                    </Flex>
                  </Flex>
                  <Flex
                    flex={1}
                    justify="center"
                    display={props.checkout ? "none" : "flex"}
                  >
                    <Icon
                      _hover={{ cursor: "pointer", opacity: "70%" }}
                      w={4}
                      h={4}
                      as={BsXCircleFill}
                      onClick={() => removeItem(i)}
                    />
                  </Flex>
                </Flex>
              );
            })}
          {discountItems.map((item, i) => {
            if (item.toggle && i > 1) {
              return (
                <Flex
                  key={"selected-discount-item:" + i}
                  borderBottom="1px"
                  borderColor="#c4c4c4"
                  borderRadius="3px"
                  pt="1rem"
                  pb="1rem"
                  justify="space-around"
                  fontSize="15px"
                >
                  <Text fontWeight={700} fontSize="15px">
                    Free {item.item}
                  </Text>
                </Flex>
              );
            } else {
              return null;
            }
          })}
        </Flex>

        <Flex direction="column">
          {subTotal > 35.0 ? (
            props.summary ? null : (
              <Flex
                color="white"
                bg="#017A39"
                justify="center"
                pt="0.7rem"
                pb="0.7rem"
                textAlign="center"
              >
                {props.checkout
                  ? "Discount available"
                  : "Discount available at checkout"}
              </Flex>
            )
          ) : null}
          {subTotal > 40.0 &&
          parseFloat(subTotal - comboTotal).toFixed(2) >= 40.0 ? (
            <Flex
              fontWeight="bold"
              justify="center"
              pt="0.5rem"
              pb="0.5rem"
              textAlign="center"
            >
              {holidayFlag ? "" : props.summary ? "" : "OR"}
            </Flex>
          ) : null}
          {subTotal > 40.0 &&
          parseFloat(subTotal - comboTotal).toFixed(2) >= 40.0 ? (
            props.summary ? null : holidayFlag ? null : (
              <Flex
                color="white"
                bg="#D88100"
                justify="center"
                pt="0.7rem"
                pb="0.7rem"
                textAlign="center"
              >
                {props.checkout
                  ? "Free item available"
                  : "Free item available at checkout"}
              </Flex>
            )
          ) : null}
        </Flex>

        <Flex direction="column" m={3}>
          <Flex
            mt="1rem"
            mb={props.checkout ? "0rem" : "1rem"}
            justify="space-between"
          >
            <VStack>
              <Text fontSize="20px" fontWeight={700}>
                Subtotal
              </Text>
              <Text color="#707070" fontSize="14px" lineHeight="0">
                {props.checkout ? null : "(without tax)"}
              </Text>
            </VStack>
            <Text fontSize="20px" fontWeight={700}>
              {"$" + parseFloat(subTotal).toFixed(2)}
            </Text>
          </Flex>

          {props.checkout ? (
            <>
              {subTotal >= 35.0 ? (
                <Flex justify="space-between">
                  <VStack>
                    <Text fontSize="20px" fontWeight={700}>
                      Discount
                    </Text>
                    <Text color="#707070" fontSize="14px" lineHeight="0">
                      {props.checkout ? null : "(without tax)"}
                    </Text>
                  </VStack>
                  <Text fontSize="20px" fontWeight={700}>
                    {discount.percentage !== 0 ? "-" : null}
                    {"$" +
                      parseFloat(subTotal * discount.percentage).toFixed(2)}
                  </Text>
                </Flex>
              ) : null}
              <VStack align="flex-start">
                <Flex
                  justify="space-between"
                  width="100%"
                  fontSize="20px"
                  fontWeight={700}
                >
                  <Text>GST</Text>
                  <Text>
                    {discount.percentage !== 0
                      ? "$" +
                        parseFloat(
                          (subTotal - subTotal * discount.percentage) * 0.05
                        ).toFixed(2)
                      : "$" + parseFloat(subTotal * 0.05).toFixed(2)}
                  </Text>
                </Flex>
                <Flex
                  justify="space-between"
                  width="100%"
                  fontSize="20px"
                  fontWeight={700}
                >
                  <Text mb="0.5rem">Total</Text>
                  <Text>
                    {"$" +
                      parseFloat(
                        (subTotal - subTotal * discount.percentage) * 1.05
                      ).toFixed(2)}
                  </Text>
                </Flex>
              </VStack>
            </>
          ) : null}

          {props.checkout ? null : (
            <Link to="/checkout">
              <Button
                width="100%"
                mt="0.7rem"
                mb={{ base: "1rem", md: "0.7rem" }}
                height={{ base: "50px", md: "54px" }}
                borderRadius="3px"
                bg="primary"
                color="secondary"
                _hover={{ opacity: "70%" }}
                isDisabled={cart.length === 0 ? true : false}
              >
                Checkout
              </Button>
            </Link>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CartBody;
