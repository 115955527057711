export const holidays = [
  {
    date: "May 11",
    type: "Mother's Day",
  },
  {
    date: "June 15",
    type: "Father's Day",
  },
  {
    date: "December 24",
    type: "Christmas Eve",
  },
  {
    date: "December 31",
    type: "New Year's Eve",
  },
  {
    date: "January 1",
    type: "New Year's Day",
  },
];

//toggle to true during the set holidays and toggle flag in maintaince.js
export const holidayFlag = false;
