import {
  Button,
  Link,
  Flex,
  Text,
  VStack,
  Image,
  Heading,
} from "@chakra-ui/react";
import React from "react";

function Landing() {
  return (
    <Flex
      height="100vh"
      align="center"
      justify={{ base: "center", md: "start" }}
      color="secondary"
      width="100%"
      position="relative"
      mt="100px"
    >
      <Flex
        direction="column"
        align={{ base: "center", md: "start" }}
        mb="15rem"
        zIndex={1}
      >
        <VStack
          fontFamily="heading"
          fontSize={{ base: "30px", md: "44px" }}
          letterSpacing={2}
          align={{ base: "center", md: "start" }}
        >
          <Heading
            fontFamily="heading"
            as="h1"
            fontWeight={700}
            mb="1rem"
            lineHeight={1}
          >
            GOLD BISTRO
          </Heading>
          <Heading
            fontFamily="heading"
            as="h1"
            fontWeight={700}
            mb="1rem"
            lineHeight={1}
          >
            CHINESE BUFFET
          </Heading>
        </VStack>
        <Button
          as={Link}
          href="/menu.pdf"
          isExternal
          mt="2rem"
          fontFamily="body"
          fontWeight={700}
          bg="accent"
          color="primary"
          borderRadius={5}
          width={170}
          height={45}
          _hover={{
            color: "white",
            bg: "#ECAF09",
            textDecoration: "none",
          }}
        >
          VIEW MENU
        </Button>
      </Flex>

      <Flex
        position="absolute"
        height="100%"
        width="100%"
        justify={{ base: "center", md: "flex-end" }}
        opacity={{ base: "60%", md: "100%" }}
      >
        <Image
          src="yuan.png"
          objectFit="cover"
          objectPosition={{ base: "88%", md: "none" }}
          alt="garden"
        ></Image>
      </Flex>
    </Flex>
  );
}

export default Landing;
