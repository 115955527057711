import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
function Banner(props) {
  return (
    <>
      <Image
        objectFit="cover"
        src="/banner.png"
        alt="banner"
        width="100vw"
        height={321}
      />
      <Flex
        align="center"
        justify="center"
        position="absolute"
        width="100%"
        height="100%"
        fontFamily="innerbody"
        fontSize={{ base: "28px", md: "44px" }}
      >
        <Text textAlign="center">ONLINE ORDERING FOR TAKEOUT/PICKUP</Text>
      </Flex>
    </>
  );
}

export default Banner;
