import React, { useEffect, useState } from "react";
import { Flex, Input, Button, Text } from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  let navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      await login(email, password);

      navigate("/admin");
    } catch (error) {
      //console.log("Error logging in");
    }

    setLoading(false);
  };

  return (
    <Flex width="100vw" height="100vh" justify="center" align="center">
      <Flex
        width={{ base: "100%", md: "40%" }}
        height={{ base: "40%", md: "30%" }}
        justify="center"
        align="center"
        bg="blackAlpha.400"
      >
        <Flex
          direction="column"
          align="center"
          width={{ base: "80%", md: "60%" }}
          height={{ base: "80%", md: "60%" }}
          p="1rem"
          bg="white"
        >
          <Input
            onChange={event => {
              setEmail(event.target.value);
            }}
            variant="filled"
            placeholder="Email"
            mb="1rem"
          ></Input>
          <Input
            onChange={event => {
              setPassword(event.target.value);
            }}
            type="password"
            placeholder="Password"
            variant="filled"
          ></Input>
          <Button size="lg" mt="1rem" onClick={() => handleLogin()}>
            Login
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Login;
