import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";

function DiscountModal(props) {
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        isCentered
        size="lg"
        mr="1rem"
        ml="1rem"
        blockScrollOnMount
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attention Customer!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight={400}>
              Please choose{" "}
              <b>
                <i>one</i>
              </b>{" "}
              of the discount options listed at the top of the website
            </Text>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DiscountModal;
