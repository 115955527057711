import React from "react";
import {
  Flex,
  Text,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { ChevronRightIcon } from "@chakra-ui/icons";

function MobileNav(props) {
  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      returnFocusOnClose={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <Flex
          align="center"
          borderTopWidth="5px"
          borderColor="accent"
          height="90px"
          justify="flex-end"
        >
          <DrawerCloseButton mr="2rem" position="static" />
        </Flex>

        <DrawerBody fontWeight="bold">
          <Flex width="100%" direction="column" mt="2rem">
            <VStack align="flex-start" mb="3rem">
              <Text color="#BDBDBD" mb="1rem" fontSize="xs">
                MAIN
              </Text>

              <Flex mb="1rem" onClick={props.onClose}>
                <Link to="/">HOME</Link>
              </Flex>

              <Flex mb="1rem" onClick={props.onClose}>
                <ChakraLink href="/menu.pdf" isExternal _hover={{}}>
                  MENU
                </ChakraLink>
              </Flex>

              <Flex mb="1rem" onClick={props.onClose}>
                <Link to="/takeout">ORDER PICKUP</Link>
              </Flex>
            </VStack>

            <Text color="#BDBDBD" mb="1rem" fontSize="xs">
              ORDER DELIVERY
            </Text>

            <Flex
              href="https://www.ubereats.com/ca/store/gold-bistro-chinese-restaurant/zTC34axQRIenOMTM6acV8w"
              as={ChakraLink}
              isExternal
              direction="row"
              justify="space-between"
              mb="1rem"
            >
              <Flex align="center">
                <Image
                  src="/ubereats.png"
                  alt="UberEats"
                  width={30}
                  height={30}
                />
                <Text ml="9px">UberEats</Text>
              </Flex>
              <ChevronRightIcon alignSelf="center" h={5} w={5} />
            </Flex>

            <Flex
              href="https://www.skipthedishes.com/gold-bistro-chinese-restaurant"
              as={ChakraLink}
              isExternal
              direction="row"
              justify="space-between"
              mb="1rem"
            >
              <Flex align="center">
                <Image
                  src="/skipthedish.png"
                  alt="SkipTheDishes"
                  width={30}
                  height={30}
                />
                <Text ml="9px">SkipTheDishes</Text>
              </Flex>
              <ChevronRightIcon alignSelf="center" h={5} w={5} />
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default MobileNav;
