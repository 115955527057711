import React from "react";
import { Flex, Text, VStack } from "@chakra-ui/react";
function Error(props) {
  return (
    <Flex width="100vw" height="100vh" align="center" justify="center">
      <VStack>
        <Text>Page 404</Text>
        <Text>Oops Nothing Here!</Text>
      </VStack>
    </Flex>
  );
}

export default Error;
