import React from "react";
import { Flex } from "@chakra-ui/react";

function Map(props) {
  return (
    <Flex height="300px">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1371.6220809695678!2d-114.06180297868599!3d50.913247274682604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537176876cf33243%3A0xc291d326e5aab3e7!2sGold%20Bistro%20Chinese%20Restaurant!5e0!3m2!1sen!2sca!4v1655778425698!5m2!1sen!2sca"
        alt="gold-bistro"
        height="100%"
        width="100%"
        title="gold-bistro"
      ></iframe>
    </Flex>
  );
}

export default Map;
