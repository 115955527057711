import { extendTheme } from "@chakra-ui/react";

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
  "3xl": "140em",
};

const colors = {
  primary: "#000000",
  secondary: "#ffffff",
  accent: "#D4AF37",
  background: "#EFEBE2",
  whiteaccent: "#F5F5F5",
};

const fonts = {
  heading: "Adamina, serif",
  chinese: "Ma Shan Zheng, cursive",
  body: "Mukta, sans-serif",
  innerbody: "Lora, serif",
};

const fontSizes = {
  xs: "14px",
  sm: "16px",
  md: "24px",
  lg: "29px",
  drinktitle: "38px",
  xl: "48px",
};

// 3. Extend the theme
const theme = extendTheme({ breakpoints, colors, fonts });

export default theme;
