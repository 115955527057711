import React from "react";
import { Flex } from "@chakra-ui/react";
function Flower(props) {
  return (
    <Flex pr={props.padding} pl={props.padding} bg={props.background}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.0968 17.4318L30 15L22.0966 12.5682C21.9161 12.0415 21.6788 11.5413 21.3916 11.0749L24.1666 5.83336L18.9251 8.60842C18.4586 8.32131 17.9585 8.08383 17.4318 7.90336L15 0L12.5682 7.90336C12.0415 8.08389 11.5413 8.32137 11.0749 8.60842L5.83336 5.83336L8.60836 11.0749C8.32137 11.5414 8.08389 12.0415 7.90336 12.5682L0 15L7.90336 17.4318C8.08389 17.9585 8.32125 18.4587 8.60836 18.9251L5.83336 24.1666L11.075 21.3917C11.5414 21.6786 12.0415 21.9161 12.5682 22.0966L15 30L17.4318 22.0966C17.9585 21.9161 18.4587 21.6786 18.9251 21.3916L24.1666 24.1666L21.3917 18.925C21.6787 18.4586 21.9162 17.9585 22.0968 17.4318ZM15 20C12.2386 20 10 17.7614 10 15C10 12.2386 12.2386 10 15 10C17.7614 10 20 12.2386 20 15C20 17.7614 17.7614 20 15 20Z"
          fill={props.color}
        />
        <path
          d="M15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12C13.3431 12 12 13.3431 12 15C12 16.6569 13.3431 18 15 18Z"
          fill={props.color}
        />
      </svg>
    </Flex>
  );
}

export default Flower;
