import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import Navbar from "../components/main-page/Navbar";
import Landing from "../components/main-page/Landing";
import Buffet from "../components/main-page/Buffet";
import Offers from "../components/main-page/Offers";
import Extra from "../components/main-page/Extra";
import Map from "../components/main-page/Map";
import Footer from "../components/main-page/Footer";
import MessageBanner from "../components/main-page/MessageBanner";
import { messageBannerFlag } from "../data/maintenance";

function Home(props) {
  return (
    <Flex width="100%" justify="center" bg="white">
      <Flex direction="column" width={{ base: "100%", "3xl": "1500px" }}>
        {messageBannerFlag ? <MessageBanner></MessageBanner> : null}
        <Flex bg="primary" justify="center">
          <Flex
            width={{ base: "90%", "3xl": "95%" }}
            direction="column"
            align="center"
          >
            <Navbar></Navbar>
            <Landing></Landing>
          </Flex>
        </Flex>
        <Flex bg="background" justify="center">
          <Flex
            width={{ base: "100%", lg: "90%", "3xl": "95%" }}
            direction="column"
          >
            <Buffet></Buffet>
          </Flex>
        </Flex>
        <Flex bg="primary" justify="center">
          <Flex
            width={{ base: "100%", lg: "90%", "3xl": "95%" }}
            direction="column"
          >
            <Offers></Offers>
          </Flex>
        </Flex>
        <Flex
          bg="background"
          justify="center"
          borderBottom="1px"
          borderTop="1px"
        >
          <Flex
            width={{ base: "100%", lg: "90%", "3xl": "95%" }}
            direction="column"
          >
            <Extra></Extra>
          </Flex>
        </Flex>
        <Map></Map>
        <Flex bg="primary" justify="center">
          <Flex width={{ base: "90%", "3xl": "97%" }} direction="column">
            <Footer></Footer>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Home;
