import React, { useContext } from "react";

import { CartContext } from "../../context/CartContext";

import { Text, SimpleGrid } from "@chakra-ui/react";

import { Element } from "react-scroll";
import { combo } from "../../data/combo";
import ComboButton from "./ComboButton";

function Combo(props) {
  const { cart, setCart } = useContext(CartContext);

  return (
    <>
      <Element name="Set Dinners" className="Element">
        <Text
          fontFamily="innerbody"
          fontWeight={700}
          fontSize="24px"
          id="Set Dinners"
        >
          Set Dinners
        </Text>
        <Text fontFamily="innerbody">
          No charge for 1 substituion of equal or less value otherwise, $2.00{" "}
          <i>minimum</i> extra charge
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt="1rem">
          {combo.setDinners.map((item, i) => {
            return <ComboButton item={item} key={"combo: " + i}></ComboButton>;
          })}
        </SimpleGrid>
      </Element>
    </>
  );
}

export default Combo;
