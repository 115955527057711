import React from "react";
import { Text, SimpleGrid } from "@chakra-ui/react";
import { Element } from "react-scroll";
import { misc } from "../../data/misc";
import ItemButton from "./ItemButton";

function Misc(props) {
  return (
    <>
      <Element name="Drinks" className="Element">
        <Text
          fontFamily="innerbody"
          fontWeight={700}
          fontSize="24px"
          id="Set Dinners"
          mt="2rem"
        >
          Drinks (355mL)
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt="1rem">
          {misc.drinks[0].items.map((item, i) => {
            return <ItemButton item={item} key={"drinks: " + i}></ItemButton>;
          })}
        </SimpleGrid>
      </Element>

      <Element name="Sauces" className="Element">
        <Text
          fontFamily="innerbody"
          fontWeight={700}
          fontSize="24px"
          id="Set Dinners"
          mt="2rem"
        >
          Sauces
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt="1rem">
          {misc.sauces[0].items.map((item, i) => {
            return <ItemButton item={item} key={"sauces: " + i}></ItemButton>;
          })}
        </SimpleGrid>
      </Element>
    </>
  );
}

export default Misc;
