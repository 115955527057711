import React from "react";

import { Flex, Text, Heading, VStack, Image } from "@chakra-ui/react";

import Flower from "../SVG/Flower";

function Offers(props) {
  return (
    <Flex
      color="whiteaccent"
      justify="center"
      zIndex={1}
      position="relative"
      overflow="hidden"
    >
      <Flex
        position="absolute"
        top="5rem"
        left={{ base: "-10rem", md: "-1rem", lg: "0rem" }}
        zIndex={2}
        transform="scaleX(-1)"
      >
        <Image src="/dragon.png" alt="dragon" width={329} height={557} />
      </Flex>

      <Flex
        position="absolute"
        bottom="5rem"
        right={{ base: "-10rem", md: "-1rem", lg: "0rem" }}
      >
        <Image src="/dragon.png" alt="dragon" width={329} height={557} />
      </Flex>

      <Flex
        mt="5rem"
        mb="5rem"
        width={{ base: "100%" }}
        direction="column"
        align="center"
        fontFamily="innerbody"
        zIndex={2}
        position="relative"
        ml="5px"
        mr="5px"
      >
        <Flex borderBottomWidth="2px" borderColor="white" position="relative">
          <Heading
            as="h1"
            textAlign="center"
            fontSize={{ base: "30px", md: "44px" }}
            fontWeight={400}
            letterSpacing={1}
            mb="1rem"
            fontFamily="innerbody"
          >
            DEALS & OFFERS
          </Heading>
          <Flex
            position="absolute"
            left="0"
            right="0"
            bottom="-15px"
            justify="center"
          >
            <Flower
              padding={"9px"}
              background={"primary"}
              color={"#F5F5F5"}
            ></Flower>
          </Flex>
        </Flex>

        <Flex textAlign="center" direction="column" mt="5rem">
          <Text fontWeight={700} fontSize={{ base: "24px", md: "32px" }}>
            TAKE-OUT OFFERS
          </Text>

          <VStack mt="3rem">
            <Text textAlign="center" fontSize={{ base: "24px", md: "32px" }}>
              Discount on pick-up orders over $35 (before GST)
            </Text>
          </VStack>

          <VStack mt="1.5rem">
            <Text textAlign="center" fontSize={{ base: "16px", md: "18px" }}>
              10% off <i>cash</i> payment
            </Text>
            <Text textAlign="center" fontSize={{ base: "16px", md: "18px" }}>
              5% off <i>credit/debit</i> payment
            </Text>
          </VStack>
        </Flex>
        <Flex textAlign="center" direction="column" mt="3rem">
          <Text fontWeight={700} fontSize={{ base: "24px", md: "32px" }}>
            OR
          </Text>

          <VStack mt="3rem" mb="4rem">
            <Text fontSize={{ base: "24px", md: "32px" }} mb="1rem">
              Free item on orders over $40 (before GST)
            </Text>
            <Flex direction="column" fontSize={{ base: "16px", md: "18px" }}>
              <Text mb="0.5rem">
                <i>Spring Rolls</i> over $40
              </Text>
              <Text mb="0.5rem">
                <i>Chicken Fried Rice</i> over $60
              </Text>
              <Text mb="0.5rem">
                <i>Ginger Beef over</i> $80
              </Text>
              <Text mb="0.5rem">
                <i>Salt & Pepper Squid</i> over $90
              </Text>
            </Flex>
          </VStack>
          <Text textAlign="center" fontSize="14px" ml="5px" mr="5px">
            *Not valid on Mother’s Day, Father’s Day, Christmas Eve, New Year’s
            Eve, New Year’s Day
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Offers;
