import React, { useContext, useState } from "react";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { CartContext } from "../../../context/CartContext";
import ConfirmationModal from "./ConfirmationModal";

function MapDiscountItem({ orderTotal, comboOrderTotal }) {
  const { setDiscount, discountItems, setDiscountItems } =
    useContext(CartContext);

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const handleToggle = index => {
    //toggle everything to else
    let tempList = discountItems.map(obj => {
      return { ...obj, toggle: false };
    });
    tempList[index].toggle = true;

    setDiscount({
      item: tempList[index].item,
      chineseName: tempList[index].chineseName,
      percentage: 0,
    });

    setDiscountItems(tempList);
  };

  const [alertItem, setAlertItem] = useState("");

  return (
    <>
      {discountItems.map((item, i) => {
        if (
          parseFloat(orderTotal - comboOrderTotal).toFixed(2) >
          parseFloat(item.range)
        ) {
          return (
            <Flex
              key={"discount-item: " + i}
              border="1px"
              borderRadius="0px"
              borderColor={item.toggle ? "black" : "#c4c4c4"}
              backgroundColor={item.toggle ? "#D88100" : "white"}
              color={item.toggle ? "white" : "black"}
              variant="outline"
              fontFamily="body"
              fontWeight={700}
              fontSize="14px"
              pt="2.2rem"
              pb="2.2rem"
              justify="center"
              _hover={{
                boxShadow: "base",
                cursor: "pointer",
              }}
              onClick={() => {
                handleToggle(i);
                setAlertItem(discountItems[i].item);
                onAlertOpen();
              }}
            >
              <Text>{item.name}</Text>
            </Flex>
          );
        } else {
          return null;
        }
      })}
      <ConfirmationModal
        alertItem={alertItem}
        isOpen={isAlertOpen}
        onOpen={onAlertOpen}
        onClose={onAlertClose}
      ></ConfirmationModal>
    </>
  );
}

export default MapDiscountItem;
