import React, { useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import theme from "./theme";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Error from "./pages/Error";
import Takeout from "./pages/Takeout";
import PrivateRoute from "./components/PrivateRoute";
import { CartContext } from "./context/CartContext";
import "./App.css";
import Checkout from "./pages/Checkout";

function App() {
  const discountItemList = [
    { name: "10% off cash", percentage: 0.1, toggle: false },
    { name: "5% off credit/debit", percentage: 0.05, toggle: false },
    {
      name: "Spring Rolls over $40",
      item: "Spring Rolls",
      chineseName: "春卷",
      range: 40.0,
      toggle: false,
    },
    {
      name: "Chicken Fried Rice over $60",
      item: "Chicken Fried Rice",
      chineseName: "鸡炒饭",
      range: 60.0,
      toggle: false,
    },
    {
      name: "Ginger Beef over $80",
      item: "Ginger Beef",
      chineseName: "干牛丝",
      range: 80.0,
      toggle: false,
    },
    {
      name: "Salt & Pepper Squid over $90",
      item: "Salt & Pepper Squid",
      chineseName: "椒盐鱿鱼",
      range: 90.0,
      toggle: false,
    },
  ];
  const [cart, setCart] = useState([]);
  const [discount, setDiscount] = useState({
    percentage: 0,
    item: "none",
  });

  const [subTotal, setSubTotal] = useState(0.0);

  const [discountItems, setDiscountItems] = useState(discountItemList);

  return (
    <ChakraProvider theme={theme}>
      <CartContext.Provider
        value={{
          cart,
          setCart,
          discount,
          setDiscount,
          discountItems,
          setDiscountItems,
          subTotal,
          setSubTotal,
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="takeout" element={<Takeout />} />
          <Route path="login" element={<Login />} />
          <Route path="checkout" element={<Checkout />} />

          <Route
            path="admin"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </CartContext.Provider>
    </ChakraProvider>
  );
}

export default App;
