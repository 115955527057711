import React, { useContext, useState } from "react";
import { Flex, SimpleGrid, Text, useDisclosure } from "@chakra-ui/react";
import ConfirmationModal from "./ConfirmationModal";
import { CartContext } from "../../../context/CartContext";

function CashDebitDiscount() {
  const { setDiscount, discountItems, setDiscountItems } =
    useContext(CartContext);

  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const handleToggle = index => {
    //toggle everything to else
    let tempList = discountItems.map(obj => {
      return { ...obj, toggle: false };
    });
    tempList[index].toggle = true;
    setDiscount({
      item: "none",
      percentage: tempList[index].percentage,
    });
    setDiscountItems(tempList);
  };
  const [alertItem, setAlertItem] = useState("");
  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={5}
        width={{ base: "100%", lg: "80%" }}
      >
        {discountItems.map((item, i) => {
          if (i <= 1) {
            return (
              <Flex
                key={"cash/debit: " + i}
                border="1px"
                borderRadius="0px"
                borderColor={item.toggle ? "black" : "#c4c4c4"}
                backgroundColor={item.toggle ? "#017A39" : "white"}
                color={item.toggle ? "white" : "black"}
                variant="outline"
                fontFamily="body"
                fontWeight={700}
                fontSize="14px"
                pt="2rem"
                pb="2rem"
                justify="center"
                _hover={{
                  boxShadow: "base",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleToggle(i);
                  setAlertItem(discountItems[i].name);
                  onAlertOpen();
                }}
              >
                <Text>{item.name}</Text>
              </Flex>
            );
          } else {
            return null;
          }
        })}
        <ConfirmationModal
          discount={true}
          alertItem={alertItem}
          isOpen={isAlertOpen}
          onOpen={onAlertOpen}
          onClose={onAlertClose}
        ></ConfirmationModal>
      </SimpleGrid>
    </>
  );
}

export default CashDebitDiscount;
