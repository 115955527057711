import React, { useContext, useEffect, useState } from "react";
import { SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { CartContext } from "../../context/CartContext";
import MapDiscountItem from "./Discount/MapDiscountItem";
import CashDebitDiscount from "./Discount/CashDebitDiscount";
import moment from "moment";

import { holidays } from "../../data/holidays";
moment().format();

function Discount(props) {
  const { cart, discountItems, setDiscountItems } = useContext(CartContext);
  const [orderTotal, setOrderTotal] = useState(0);
  const [comboOrderTotal, setcomboOrderTotal] = useState(0);
  //boolean to hide discount items
  const today = moment().format("MMMM D");
  const holiday = holidays.some(date => date["date"] === today);

  useEffect(() => {
    let total = 0;
    let tempComboTotal = 0;
    for (let i = 0; i < cart.length; i++) {
      total = total + cart[i].price * cart[i].quantity;
      if (cart[i].combo) {
        tempComboTotal = tempComboTotal + cart[i].price * cart[i].quantity;
      }
    }
    setOrderTotal(total.toFixed(2));
    setcomboOrderTotal(tempComboTotal.toFixed(2));
  }, [cart]);

  return (
    <>
      {orderTotal > 35.0 ? (
        <>
          <VStack
            align="flex-start"
            fontSize="24px"
            fontWeight={700}
            mb="1rem"
            width={{ base: "100%", lg: "80%" }}
          >
            <Text mb="1rem">Choose One Option</Text>
            <Text>Discount</Text>
          </VStack>
          <CashDebitDiscount></CashDebitDiscount>

          {parseFloat(orderTotal - comboOrderTotal) > 40 ? (
            <Text
              fontFamily="inner"
              fontSize="24px"
              fontWeight={700}
              mb="1rem"
              mt="1rem"
            >
              {!holiday ? "Free Item" : ""}
            </Text>
          ) : null}
          {!holiday ? (
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={5}
              width={{ base: "100%", lg: "80%" }}
            >
              <MapDiscountItem
                orderTotal={orderTotal}
                comboOrderTotal={comboOrderTotal}
              ></MapDiscountItem>
            </SimpleGrid>
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default Discount;
