import React from "react";
import CustomerOrder from "./CustomerOrder";

//https://github.com/gregnb/react-to-print/issues/404
export class CustomerOrderWrapper extends React.Component {
  render() {
    return (
      <CustomerOrder customerOrders={this.props.customerOrders}></CustomerOrder>
    );
  }
}
