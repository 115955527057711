import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";

function ConfirmationModal(props) {
  const cancelRef = React.useRef();

  return (
    <>
      <AlertDialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        motionPreset="slideInBottom"
        isCentered
        size={{ base: "sm", md: "2xl" }}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Attention Customer
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex
                direction="column"
                height={{ base: "200px", md: "180px" }}
                justify="space-between"
              >
                {props.discount ? (
                  <Text>
                    You have selected <b>{props.alertItem}</b> when paying
                  </Text>
                ) : (
                  <Text>
                    You have selected a free order of <b>{props.alertItem}</b>
                  </Text>
                )}
                <Flex direction="column" fontWeight="bold" fontSize="13px">
                  <Text mt="5px">
                    **Free items are not valid on any set dinner combos
                  </Text>
                  <Text mt="5px">**Customers may only select ONE option</Text>
                  <Text mt="5px">
                    **Not valid on Mother’s Day, Father’s day, Christmas Eve,
                    New Year’s Eve & New Year’s Day
                  </Text>
                </Flex>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="green" onClick={props.onClose} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default ConfirmationModal;
